import NavBar from "./components/NavBar";
import BannerSlider from "./components/BannerSlider";

import Nosotros from "./sections/Nosotros";
import Soluciones from "./sections/Soluciones";
import ChatButton from "./components/ChatButton";
import Experiencia from "./sections/Experiencia";
import Clientes from "./sections/Clientes";
import Contacto from "./sections/Contacto";
import Footer from "./sections/Footer";
import { HashRouter as Router } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const bannerImagesMd = [
  {
    id: 1,
    src: "/img/banner/1md.webp",
  },
  {
    id: 2,
    src: "/img/banner/2md.webp",
  },
  {
    id: 3,
    src: "/img/banner/3md.webp",
  },
];

const bannerImagesLg = [
  {
    id: 1,
    src: "/img/banner/1lg.webp",
  },
  {
    id: 2,
    src: "/img/banner/2lg.webp",
  },
  {
    id: 3,
    src: "/img/banner/3lg.webp",
  },
];

function App() {
  const { ref: homeRef, inView: homeIsVisible } = useInView();
  const { ref: nosotrosRef, inView: nosotrosIsVisible } = useInView();
  const { ref: solucionesRef, inView: solucionesIsVisible } = useInView();
  const { ref: experienciaRef, inView: experienciaIsVisible } = useInView();
  const { ref: clientesRef, inView: clientesIsVisible } = useInView();
  const { ref: contactoRef, inView: contactoIsVisible } = useInView();

  useEffect(() => {
    AOS.init({ duration: 1200 });
  });

  return (
    <Router>
      <div ref={homeRef} id="home" className=""></div>
      <div className="App  overflow-x-hidden ">
        <div className="triangle-background h-screen w-screen fixed "></div>
        <NavBar
          sections={{
            homeIsVisible,
            nosotrosIsVisible,
            solucionesIsVisible,
            experienciaIsVisible,
            clientesIsVisible,
            contactoIsVisible,
          }}
        />

        <div className="z-20 relative">
          <div className="w-full h-[80px] lg:pt-[105px] bg-neutral-900 bg-opacity-80"></div>
          <BannerSlider
            banners={bannerImagesMd}
            className={"relative lg:hidden"}
          />
          <BannerSlider
            banners={bannerImagesLg}
            className={"hidden lg:block"}
          />
          <Nosotros innerRef={nosotrosRef} id="nosotros" />
          <Soluciones innerRef={solucionesRef} id="soluciones" />
          <Experiencia innerRef={experienciaRef} id="experiencia" />
          <Clientes innerRef={clientesRef} id="clientes" />
          <Contacto innerRef={contactoRef} id="contacto" />
          <Footer />
          <ChatButton />
        </div>
      </div>
    </Router>
  );
}

export default App;
