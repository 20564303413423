import React from "react";
import Icon from "../components/Icon";

const Footer = () => {
  return (
    <div className="grid grid-cols-10 pt-0 bg-neutral-900 ">
      <div className="col-start-0 col-span-10  text-gray-600 font-mono text-left mb-6">
        <div className="text-white text-xs md:text-sm ml-10 tracking-wide pt-10 flex justify-between mx-10 mr-36">
          <img
            src="/img/white logo.webp"
            className="h-10 md:h-16"
            alt="SMI SOLUCIONES"
          />
          <div>
            <p className="ml-3 md:ml-0">SMI® Soluciones 2024</p>
            <div className="flex">
              <a
                href="https://www.linkedin.com/company/smisoluciones/?originalSubdomain=ar"
                target="_blank"
                rel="noreferrer"
                aria-label="LinkedIn"
              >
                <Icon linkedin className={"h-6 mx-4 mt-2"} />
              </a>
              <a href="mailto:Ventas@smisoluciones.net" aria-label="Mail">
                <Icon mail className={"h-6 mx-4 mt-2"} />
              </a>
              <a href="tel:+5491166883796" aria-label="Teléfono">
                <Icon phone className={"h-6 mx-4 mt-2"} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
