import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
// Card Slider
import InformationCard from "../components/InformationCard";

const Experiencia = ({ innerRef, id }) => {
  const isMd = useMediaQuery("(min-width:850px) and (max-width: 1023px)");
  const isLg = useMediaQuery("(min-width:1024px)  and (max-width: 1279px)");
  const isXl = useMediaQuery("(min-width:1280px)  and (max-width: 1535px)");
  const is2Xl = useMediaQuery("(min-width:1536px)");

  const [autoPlay, setAutoPlay] = useState(true);
  const [imageQuantity, setImageQuantity] = useState(0);

  useEffect(() => {
    if (isMd || isLg) setImageQuantity(2);
    else if (isXl || is2Xl) {
      setImageQuantity(3);
      setAutoPlay(false);
    } else {
      setImageQuantity(1);
      setAutoPlay(true);
    }
  }, [isMd, isLg, isXl, is2Xl]);

  const titleClassName = classNames(
    "text-3xl md:text-4xl lg:text-5xl font-sans font-bold text-left md:text-transparent font-outline-2 uppercase tracking-widest"
  );

  return (
    <div
      className="grid grid-cols-10 pt-36 bg-neutral-500 bg-opacity-40 "
      id={id}
    >
      <div
        className="col-start-2 col-span-8 2xl:col-start-3 2xl:col-span-6 text-neutral-700 font-inter text-left"
        ref={innerRef}
      >
        <div className="mb-10 md:mb-20">
          <h1 className={titleClassName}>Nuestro trabajo</h1>
          <div className="text-left mt-4 md:mt-8 text-sm lg:text-lg pl-2 pr-2">
            <p>
              Desde la conceptualización hasta la puesta en marcha, cada paso es
              meticulosamente planificado y ejecutado para garantizar el
              cumplimiento de los más altos estándares de tiempo y calidad. Nos
              comprometemos a brindar respuestas rápidas y precisas a las
              necesidades de nuestros clientes, asegurando que sus proyectos
              avancen sin contratiempos.
            </p>
          </div>
        </div>
        <div className="my-20 flex justify-center">
          <InformationCard
            title={"Diseño y Simulación"}
            text={
              "Utilizamos las más avanzadas herramientas de software para diseño 3d, simulación de procesos, implementando también realidad virtual para mantenernos a la vanguardia en la industria."
            }
            textLg={`Nuestro departamento de ingenieria cuenta con personal para realizar cualquier tipo de proyecto. Utilizando lo mas avanzado en software que nos permiten realizar diseños en 3d como tambien simulación de todos los procesos todo esto con la capacidad de ver los proyectos en realidad virtual. Gracias a la aplicación de las últimas tecnologías en desarrollo y simulación, podemos mostrarte todo el proyecto antes de fabricarlo.
            `}
            imgSource={"/img/cardImages/image9.webp"}
          />
        </div>
        <div className="my-20 flex justify-center">
          <InformationCard
            title={"Fabricaciones"}
            text={
              "Contamos con fabricación propia, donde realizamos corte, mecanizados, soldadura, armado final y tratamientos térmicos y de superficie, garantizando así la calidad deseada por los clientes."
            }
            textLg={
              "Contamos con fabricación propia donde realizamos desde el corte de las piezas por pantógrafo, mecanizados, soldadura, tratamientos térmicos hasta el armado final con el tratamiento superficial correspondiente. De esta manera garantizamos que todos los productos de nuestros clientes obtengan la calidad deseada ya sea un dispositivo productivo, cerramiento, gripper, manipulador y demás. "
            }
            imgSource={"/img/cardImages/image18.webp"}
          />
        </div>
        <div className="my-20 flex justify-center">
          <InformationCard
            title={"Integraciones"}
            text={
              "Nos enorgullese poder darle a nuestros clientes todas las soluciones a sus proyectos. Trabajamos con múltiples marcas y bajo los estándares demandamos por cada cliente."
            }
            textLg={
              "Como integradores nos orgullece poder darles a nuestros clientes todas las soluciones a sus proyectos. Disponemos de un equipo acostumbrado a trabajar con múltiples marcas y bajo los estándares demandamos por cada uno de nuestros clientes."
            }
            imgSource={"/img/cardImages/image25.webp"}
          />
        </div>
        <div className="my-20 flex justify-center">
          <InformationCard
            title={"Asistencias profesionales"}
            text={
              "Entre el abanico de soluciones, disponemos a nuestros clientes la oportunidad de disponer asistencia además de la integraciones o proyectos llave en mano. "
            }
            textLg={`Entre el abanico de soluciones, disponemos a nuestros clientes la oportunidad de disponer asistencia además de la integraciones o proyectos llave en mano. Esto nos ayuda a que nuestros clientes cuente con la resolución a problemas o necesidades espondaicos.

`}
            imgSource={"/img/cardImages/tablero.webp"}
          />
        </div>
      </div>
      <div className="col-start-1 col-span-10 md:mt-20 p-10"></div>
    </div>
  );
};

export default Experiencia;
