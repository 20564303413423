import React from "react";
import classNames from "classnames";
import LogoSlider from "../components/LogoSlider";

const logoImages = [
  {
    id: 0,
    src: "/img/logos/Toyota.webp",
  },
  {
    id: 1,
    src: "/img/logos/Ford.webp",
  },
  {
    id: 2,
    src: "/img/logos/MercedesBenz.webp",
  },
  {
    id: 3,
    src: "/img/logos/Volkswagen.webp",
  },
  {
    id: 4,
    src: "/img/logos/Stellantis.webp",
  },
  {
    id: 5,
    src: "/img/logos/Gestamp.webp",
  },
  {
    id: 6,
    src: "/img/logos/Wega.webp",
  },
  {
    id: 7,
    src: "/img/logos/Mabe.webp",
  },
  {
    id: 8,
    src: "/img/logos/Hirotoc.webp",
  },
  {
    id: 9,
    src: "/img/logos/Cormetal.webp",
  },
  {
    id: 10,
    src: "/img/logos/Wooshin.webp",
  },
  {
    id: 11,
    src: "/img/logos/PolimetalSA.webp",
  },
];

const Clientes = ({ innerRef, id }) => {
  const titleClassName = classNames(
    "text-3xl md:text-4xl lg:text-5xl font-sans font-bold text-left md:text-transparent font-outline-2-w uppercase tracking-widest"
  );
  const textClassName = classNames("mt-10 font-light text-sm md:text-base");
  const bottomTextClassName = classNames(
    textClassName,
    "hidden lg:block mt-4 not-italic text-start"
  );
  return (
    <>
      <div
        className="grid grid-cols-10 pt-36 bg-neutral-950 bg-opacity-80 pb-16"
        ref={innerRef}
        id={id}
      >
        <div className="col-start-2 col-span-8 2xl:col-start-3 2xl:col-span-6 text-gray-100 font-inter text-left">
          <h1 className={titleClassName}>Nuestros Clientes </h1>
          <h4 className={textClassName}>
            <p>
              Trabajamos con numerosas empresas de distintos tamaños y sectores,
              las cuáles respaldan nuestra experiencia y compromiso.
            </p>
            <p>
              Nos interesa no sólo proveer soluciones a nuestros clientes sino
              formar relaciones sólidas duraderas que nos aporten a crecer
              juntos.{" "}
            </p>
            <p className="font-bold italic mt-2">
              " En SMI Soluciones estamos comprometidos con tu crecimiento a
              largo plazo "
            </p>
          </h4>
          <h4 className={bottomTextClassName}>
            A continuación te mostramos un vistazo de las empresas que confían y
            respaldan nuestro trabajo. ¡Nos encantaría poder sumarte!
          </h4>
        </div>
      </div>
      <LogoSlider logos={logoImages} />
      <div className="h-10 bg-neutral-950 bg-opacity-80"></div>
    </>
  );
};

export default Clientes;
