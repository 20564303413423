import React, { useRef, useState } from "react";
import classNames from "classnames";
import emailjs from "@emailjs/browser";
import Icon from "../components/Icon";
import Modal from "../components/Modal";

const Contacto = ({ innerRef, id }) => {
  const titleClassName = classNames(
    "text-3xl md:text-4xl lg:text-5xl font-sans font-bold text-left font-outline-2 uppercase tracking-widest text-black"
  );

  const form = useRef();

  const [modalDisplayed, setModalDisplayed] = useState(false);

  const inputClassName =
    "w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-sm focus:outline-none focus:shadow-outline opacity-80 focus:opacity-100 ease-linear transition-all duration-200";
  const initialFormState = {
    email: "",
    name: "",
    surname: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialFormState);

  async function sendEmail(text) {
    emailjs
      .sendForm(
        "smi_contact_form",
        "template_0wpcbd2",
        form.current,
        "2UQ9D4YZLebf36Nb0"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const closeModal = () => {
    setModalDisplayed(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setModalDisplayed(true);
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const text = `<p><p>From: ${formData.surname}, ${formData.name}</p><p>Address: ${formData.email}</p><p>Subject: ${formData.message}</p></p>`;

      sendEmail(text);
      console.log(formData);
      setFormData(initialFormState);
    }
  };

  const validateForm = (data) => {
    let errors = {};

    if (!data.name.trim()) {
      errors.name = "Ingresa tu nombre";
    }
    if (!data.surname.trim()) {
      errors.surname = "Ingresa tu apellido";
    }
    if (!data.email.trim()) {
      errors.email = "Se requiere un correo";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Correo inválido";
    }
    if (!data.message.trim()) {
      errors.message = "El mensaje está vacío";
    }
    return errors;
  };

  const isValidEmail = (email) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <Modal condition={modalDisplayed} closeModal={closeModal} />
      <div
        className="grid grid-cols-10 pt-0 bg-opacity-60 bg-neutral-500 "
        ref={innerRef}
        id={id}
        data-aos="fade-in"
      >
        <div className="col-start-2 col-span-10 md:col-start-2 md:col-span-8 2xl:col-start-3  2xl:col-span-6 text-gray-600 font-inter text-left mt-36 mb-20">
          <h1 className={titleClassName}>Contactate con Nosotros</h1>
        </div>
        <div
          className="col-start-0 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-2  lg:col-span-4 xl:col-start-3 xl:col-span-4  bg-neutral-800 md:rounded-xl lg:rounded-r-none md:my-6 shadow-black shadow"
          data-aos="fade-right"
        >
          <form ref={form} onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-5 mx-5 mt-5">
              <div className="flex flex-col">
                <input
                  className={`${inputClassName} rounded-tl-xl`}
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Nombre*"
                />
                {errors.name && <p className="text-red-500">{errors.name}</p>}
              </div>

              <div className="flex flex-col">
                <input
                  className={inputClassName}
                  type="text"
                  name="surname"
                  value={formData.surname}
                  onChange={handleInputChange}
                  placeholder="Apellido*"
                />
                {errors.surname && (
                  <p className="text-red-500">{errors.surname}</p>
                )}
              </div>

              <div className="flex flex-col">
                <input
                  className={inputClassName}
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Correo*"
                />
                {errors.email && <p className="text-red-500">{errors.email}</p>}
              </div>
            </div>

            <div className="my-4 mx-5">
              <textarea
                name="message"
                placeholder="Mensaje*"
                value={formData.message}
                onChange={handleInputChange}
                className={`w-full h-32 bg-gray-100 ${
                  errors.message ? "border-red-500" : ""
                } opacity-80 focus:opacity-100 text-gray-900 mt-2 p-3 rounded-sm focus:outline-none focus:shadow-outline`}
              ></textarea>
              {errors.message && (
                <p className="text-red-500">{errors.message}</p>
              )}
            </div>

            <div className="my-2 w-1/2 lg:w-1/4 ml-5 mb-10 lg:mb-0">
              <div className="bg-gradient-to-r from-amber-700 via-red-600 to-red-800 w-full h-full rounded-bl-xl">
                <button
                  type="submit"
                  className="uppercase text-sm font-bold tracking-wide py-4 bg-gray-50 hover:bg-transparent inline-block px-2 hover:text-gray-50 ease-linear transition-all duration-300 text-neutral-900 p-3 rounded-bl-xl w-full"
                  aria-label="Enviar Correo de Contacto"
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
        <div
          className="col-start-0 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-6 lg:col-span-4 xl:col-start-7 shadow-lg shadow-neutral-600 xl:col-span-3 py-20 bg-neutral-200 md:rounded-xl lg:rounded-l-none font-inter text-neutral-800"
          data-aos="fade-left"
        >
          <div className="mx-10 italic">
            <p>
              " Valoramos la comunicación directa con nuestros clientes y
              colaboradores. Si tenés preguntas, comentarios o estás interesado
              en trabajar con nosotros, no dudes en ponerte en contacto.{" "}
            </p>
            <p>
              {" "}
              Contá con nosotros para ayudarte en todo lo que necesites. Estamos
              para hacer realidad tus proyectos "
            </p>
          </div>
          <p className="text-right mx-10 mt-4">- Equipo de SMI</p>
          <div className="mx-10 mt-14">
            <p className="text-left underline underline-offset-4 font-semibold text-lg mb-10">
              Encontranos en
            </p>
            <div className="flex my-6  rounded-full bg-slate-500 bg-opacity-10 hover:bg-gray-50 transition-all ease-linear duration-200">
              <Icon
                disableHover
                map
                className={
                  "h-7 justify-start p-4 px-5 rounded-full bg-slate-500 bg-opacity-10"
                }
              />
              <a
                href="https://maps.app.goo.gl/RVbLdgjXBiYnuB529"
                aria-label="Mapa Ubicación Buenos Aires"
                target="_blank"
                rel="noreferrer"
                className="py-4 ml-4 text-sm md:text-base"
              >
                General Pacheco. (Bs. As.).
              </a>
            </div>
            <div className="flex my-6  rounded-full bg-slate-500 bg-opacity-10 hover:bg-gray-50 transition-all ease-linear duration-200">
              <Icon
                disableHover
                map
                className={
                  "h-7 justify-start p-4 px-5 rounded-full bg-slate-500 bg-opacity-10"
                }
              />
              <a
                href={`https://maps.app.goo.gl/7eLvAvjWAW2dXwJ36`}
                aria-label="Mapa Ubicación Catamarca"
                target="_blank"
                rel="noreferrer"
                className="py-4 ml-4 text-sm md:text-base"
              >
                Recreo (Catamarca).
              </a>
            </div>
            <a
              href="https://maps.app.goo.gl/h4jawDEu4PmZie8c7"
              aria-label="Mapa Ubicación Villa Nueva"
              target="_blank"
              rel="noreferrer"
              className="flex my-6  rounded-full bg-slate-500 bg-opacity-10 hover:bg-gray-50 transition-all ease-linear duration-200"
            >
              <Icon
                disableHover
                map
                className={
                  "h-7 justify-start p-4 px-5 rounded-full bg-slate-500 bg-opacity-10"
                }
              />
              <p className="py-4 ml-4 text-sm md:text-base">
                Villa Nueva. (Cba.).
              </p>
            </a>
          </div>
        </div>
        <div className="col-start-1 col-span-10 md:mt-20 p-10"></div>
      </div>
    </>
  );
};

export default Contacto;
