import classNames from "classnames";
import React, { useEffect, useState } from "react";

function Modal({ condition, closeModal }) {
  const [isDisplayed, setIsDisplayed] = useState(condition);

  useEffect(() => {
    setIsDisplayed(condition);
  }, [condition]);

  const finalClassName = classNames(
    isDisplayed
      ? "fixed z-50 left-0 top-0 w-screen h-screen flex justify-center items-center bg-black bg-opacity-70 transition-opacity bg-opacity-75"
      : "hidden"
  );

  const handleClick = () => {
    setIsDisplayed(false);
    closeModal();
  };

  if (condition) {
    return (
      <div>
        <div className={finalClassName}>
          <div className="bg-neutral-200  shadow-md p-8 md:min-w-[30%] min-h-[50%] lg:min-h-[35%] border border-stone-700 rounded-md relative">
            <div
              className="flex flex-col items-center mt-2 align-middle 
            text-neutral-900 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="-top-0.5 z-10  h-14 w-14 rounded-full bg-green-500 bg-opacity-10 text-green-700  inline-flex  mb-3"
              >
                <path
                  fillRule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                  clipRule="evenodd"
                />
              </svg>
              <p className="text-lg">Se ha enviado el correo!</p>
              <div className="text-md p-4 align-middle">
                <p>Gracias por comunicarse con nosotros</p>
              </div>
              <button
                onClick={handleClick}
                className="border bg-neutral-600 rounded-md py-3 px-4 absolute bottom-4 hover:bg-neutral-700 ease-linear duration-200 transition-all text-neutral-50"
                aria-label="Volver a Página"
              >
                Volver
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
