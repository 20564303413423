import React, { useState } from "react";
import Card from "../components/Card";
const Nosotros = ({ innerRef, id }) => {
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const iconColor = "#000000";
  const cardsData = [
    {
      title: "Misión",
      description:
        "Brindar a nuestros clientes atención personalizada con los pilares en flexibilidad, desarrollo profesional, satisfacción, ética y confiabilidad.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-flag-star pl-2 pt-2"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke={iconColor}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M11.475 13.551a5.001 5.001 0 0 0 -6.475 .449v-9a5 5 0 0 1 7 0a5 5 0 0 0 7 0v5" />
          <path d="M5 21v-7" />
          <path d="M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" />
        </svg>
      ),
    },
    {
      title: "Visión",
      description:
        "Ser parte de la industrialización y fabricación nacional aplicando los conocimientos tecnológicos nacionales e internacionales para hacer que los productos de nuestros clientes puedan competir en el mercado a nivel mundial.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-eye-pin pl-2 pt-2"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke={iconColor}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
          <path d="M12 18c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.362 0 6.202 1.745 8.517 5.234" />
          <path d="M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z" />
          <path d="M19 18v.01" />
        </svg>
      ),
    },
    {
      title: "Objetivos",
      description:
        "Cumplir con estándares de tiempo y calidad demandados, brindando respuestas cortas y precisas en relación a diseño, fabricación, armado, puesta a punto e integración.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-target-arrow pl-2 pt-2"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke={iconColor}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
          <path d="M12 7a5 5 0 1 0 5 5" />
          <path d="M13 3.055a9 9 0 1 0 7.941 7.945" />
          <path d="M15 6v3h3l3 -3h-3v-3z" />
          <path d="M15 9l-3 3" />
        </svg>
      ),
    },
  ];

  return (
    <div id={id}>
      <div className="grid grid-cols-10 pt-36 bg-neutral-300 bg-opacity-60 ">
        <div className="col-start-2 col-span-8 2xl:col-start-3 2xl:col-span-6 text-neutral-700 font-inter">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-sans font-bold text-left md:text-transparent font-outline-2 uppercase tracking-widest">
            Quiénes somos{" "}
          </h1>

          <div className="text-left mt-4 md:mt-8 text-sm lg:text-lg">
            <p className=" py-2 lg:py-4">
              En SMI Soluciones te ofrecemos una gama completa de servicios
              sobre los procesos productivos adaptados a tus necesidades
              específicas. Contamos con la experiencia y la capacidad de llevar
              a cabo proyectos en los sectores
              <span className="font-bold ">
                {` Agro, Alimenticio, Químico, Autopartes e Industria Automotriz`}
              </span>{" "}
              entre muchos otros.
            </p>
            <p>
              " Con un equipo lleno de talento y una trayectoria sólida en la
              industria, te ofrecemos soluciones a medida que no solo mejoran la
              eficiencia, calidad y rentabilidad, sino que también hacen que
              cada paso del proceso sea más fácil.{" "}
              <span className="font-bold">
                Desde el momento en que surge la idea hasta la puesta en marcha
                de esta
              </span>{" "}
              <span>
                estamos acá para superar tus expectativas y ayudarte a hacer
                realidad tu visión. "
              </span>
            </p>

            <p className="pt-2 lg:pt-4 italic">
              " Nuestro éxito se mide por el éxito de nuestros clientes. Confiá
              en nosotros para llevar tus procesos productivos al siguiente
              nivel. "
            </p>
          </div>
          <div
            className="mt-20 grid grid-cols-3 md:grid-cols-6 xl:grid-cols-9  gap-4  "
            ref={innerRef}
            data-aos="fade-down"
          >
            {cardsData.map((card, index) => (
              <Card
                key={index}
                title={card.title}
                description={card.description}
                icon={card.icon}
                isHovered={hoveredCardIndex === index}
                className={"col-span-3 my-4 "}
                setHovered={() => {
                  setHoveredCardIndex(index);
                }}
              />
            ))}
          </div>
        </div>
        <div className="col-start-1 col-span-10 mt-20 p-10"></div>
      </div>
    </div>
  );
};

export default Nosotros;
