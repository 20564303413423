import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "@react-hook/media-query";

import classNames from "classnames";

const LogoSlider = ({ logos, className }) => {
  const logoClassName = classNames("items-center");

  const logoSliderFinalClassName = classNames(
    "relative py-5 bg-neutral-400 bg-opacity-50",
    className
  );

  const isMd = useMediaQuery("(min-width:768px) and (max-width: 1023px)");
  const isLg = useMediaQuery("(min-width:1024px)  and (max-width: 1279px)");
  const isXl = useMediaQuery("(min-width:1280px)  and (max-width: 1535px)");
  const is2Xl = useMediaQuery("(min-width:1536px)");

  let slidesQuantity;
  if (isMd) {
    slidesQuantity = 4;
  } else if (isLg) {
    slidesQuantity = 5;
  } else if (isXl) {
    slidesQuantity = 6;
  } else if (is2Xl) {
    slidesQuantity = 7;
  } else {
    slidesQuantity = 3;
  }

  const settings = {
    infinite: true,
    slidesToShow: slidesQuantity, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true,
    autoplaySpeed: 3000, // Interval between slides in milliseconds
    pauseOnFocus: false,
    pauseOnHover: false,
  };

  const slider = logos.map((logo, index) => {
    return (
      <div key={index}>
        <img className={logoClassName} src={logo.src} alt={"banner " + index} />
      </div>
    );
  });

  return (
    <div className={logoSliderFinalClassName} data-aos="fade-left">
      <Slider {...settings}>{slider}</Slider>
    </div>
  );
};

export default LogoSlider;
