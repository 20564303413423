import React from "react";
import AutoCardSlider from "../components/AutoCardSlider";
import classNames from "classnames";
const Soluciones = ({ innerRef, id }) => {
  const titleClassName = classNames(
    "text-3xl md:text-4xl lg:text-5xl font-sans font-bold text-left md:text-transparent font-outline-2-w uppercase tracking-widest"
  );

  const solutionsSlides = [
    <div
      className="ease-linear transition-all duration-200 w-full text-gray-100 flex h-96 md:h-80 "
      key={1}
    >
      <div className="flex my-10 flex-col md:flex-row justify-center md:justify-normal">
        <div className="bg-gradient-to-r from-amber-700 via-red-600 to-red-800 ml-20 mb-5 md:mb-0 rounded-full w-16 h-16 md:w-48 md:h-48">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-asset w-16 h-16 md:w-48 md:h-48 aspect-square "
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#FFFF"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M9 15m-6 0a6 6 0 1 0 12 0a6 6 0 1 0 -12 0" />
            <path d="M9 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
            <path d="M19 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
            <path d="M14.218 17.975l6.619 -12.174" />
            <path d="M6.079 9.756l12.217 -6.631" />
            <path d="M9 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
          </svg>
        </div>
        <div className="ml-10">
          <h3 className="text-lg md:text-2xl font-semibold mb-2">Ingeniería</h3>
          <p className="md:ml-10 lg:text-base mr-2 md:mr-10 text-left mt-4 md:mt-8 text-sm xl:text-lg pr-5">
            Ofrecemos servicios integrales de ingeniería, como la definición de
            procesos, el diseño final en 3D y 2D, incluyendo simulación y
            estudio de elementos finitos. Nos especializamos en diseño mecánico
            y eléctrico, así como en programación robótica y de PLC para
            automatización precisa.
          </p>
        </div>
      </div>
    </div>,
    <div
      className="ease-linear transition-all duration-200 w-full text-gray-100 flex h-96 md:h-80"
      key={2}
    >
      <div className="flex my-10 flex-col md:flex-row justify-center md:justify-normal">
        <div className="bg-gradient-to-r from-amber-700 via-red-600 to-red-800 ml-20 mb-5 md:mb-0 rounded-full w-16 h-16 md:w-48 md:h-48">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-building-factory-2 w-16 h-16 md:w-48 md:h-48 aspect-square "
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#FFFF"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 21h18" />
            <path d="M5 21v-12l5 4v-4l5 4h4" />
            <path d="M19 21v-8l-1.436 -9.574a.5 .5 0 0 0 -.495 -.426h-1.145a.5 .5 0 0 0 -.494 .418l-1.43 8.582" />
            <path d="M9 17h1" />
            <path d="M14 17h1" />
          </svg>
        </div>
        <div className="ml-10">
          <h3 className="text-lg md:text-2xl font-semibold mb-2">
            Fabricación
          </h3>
          <p className="md:ml-10 lg:text-base mr-2 md:mr-10 text-left mt-4 md:mt-8 text-sm xl:text-lg pr-5">
            Expertos en procesos de fabricación avanzados, mecanizados,
            estructuras soldadas y pruebas de funcionamiento. Garantizamos
            durabilidad y calidad con pintado y tratamientos térmicos. Nos
            enfocamos en la excelencia y el cumplimiento de estándares
            exigentes.
          </p>
        </div>
      </div>
    </div>,
    <div
      className="ease-linear transition-all duration-200 w-full text-gray-100 flex h-96 md:h-80"
      key={3}
    >
      <div className="flex my-10 flex-col md:flex-row justify-center md:justify-normal">
        <div className="bg-gradient-to-r from-amber-700 via-red-600 to-red-800 ml-20 mb-5 md:mb-0 rounded-full w-16 h-16 md:w-48 md:h-48">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-brand-databricks w-16 h-16 md:w-48 md:h-48 aspect-square"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#FFFF"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 17l9 5l9 -5v-3l-9 5l-9 -5v-3l9 5l9 -5v-3l-9 5l-9 -5l9 -5l5.418 3.01" />
          </svg>
        </div>
        <div className="ml-10">
          <h3 className="text-lg md:text-2xl font-semibold mb-2">
            Integraciones
          </h3>
          <p className="md:ml-10 lg:text-base mr-2 md:mr-10 text-left mt-4 md:mt-8 text-sm xl:text-lg pr-5">
            Contamos con personal especializado en la integración de sistemas
            complejos, como celdas robotizadas y tableros de control.
            Coordinamos cada componente para maximizar la productividad.
            Ofrecemos soluciones personalizadas que optimizan el rendimiento y
            la eficiencia industrial.
          </p>
        </div>
      </div>
    </div>,
    <div
      className="ease-linear transition-all duration-200 w-full text-gray-100 flex h-96 md:h-80"
      key={4}
    >
      <div className="flex my-10 flex-col md:flex-row justify-center md:justify-normal">
        <div className="bg-gradient-to-r from-amber-700 via-red-600 to-red-800 ml-20 mb-5 md:mb-0 rounded-full w-16 h-16 md:w-48 md:h-48">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-info-hexagon w-16 h-16 md:w-48 md:h-48 aspect-square"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#FFFF"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z" />
            <path d="M12 9h.01" />
            <path d="M11 12h1v4h1" />
          </svg>
        </div>
        <div className="ml-10">
          <h3 className="text-lg md:text-2xl font-semibold mb-2">
            Asistencias
          </h3>
          <p className="md:ml-10 lg:text-base mr-2 md:mr-10 text-left mt-4 md:mt-8 text-sm xl:text-lg pr-5">
            Nuestro equipo técnico ofrece soporte en mecánica, eléctrica,
            robótica y PLC. Garantizamos un funcionamiento óptimo con respuestas
            rápidas y soluciones efectivas para mantenimiento y diagnóstico de
            problemas.
          </p>
        </div>
      </div>
    </div>,
  ];

  return (
    <div
      className="grid grid-cols-10 pt-36 bg-neutral-950 bg-opacity-90 "
      id={id}
    >
      <div className="col-start-2 col-span-8 2xl:col-start-3 2xl:col-span-6 text-gray-100 font-inter text-left">
        <h1 className={titleClassName}>Soluciones </h1>
        <div className="mt-5 md:mt-20" ref={innerRef}>
          <AutoCardSlider
            slides={solutionsSlides}
            slidesQuantity={1}
            autoPlay={true}
            pauseOnFocus={false}
            pauseOnHover={false}
          />
        </div>
      </div>
      <div className="col-start-1 col-span-10 md:mt-20 p-10"></div>
    </div>
  );
};

export default Soluciones;
