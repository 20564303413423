import Icon from "./Icon";
import classNames from "classnames";
import { useState } from "react";
const ChatButton = () => {
  const [isHovered, setIsHovered] = useState(false);
  const textBoxClassName = classNames(
    " pl-6  ease-linear transition-all duration-300 -mr-10 overflow-x-hidden",
    isHovered
      ? "block  bg-neutral-700 shadow-neutral-700 rounded-l-full shadow-md text-gray-50 w-44"
      : "w-0 text-transparent"
  );
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <a
      href="https://api.whatsapp.com/send?phone=+5491166883796&text=Hola,%20me%20comunico%20desde%20smisoluciones.com.ar.%0AMi%20consulta%20es:%20"
      target="_blank"
      aria-label="Botón de Contacto Whatsapp"
      rel="noreferrer"
      className="z-50 fixed bottom-0 right-0 flex justify-end items-end align-bottom pr-10 py-5"
    >
      <div className="relative">
        <div className="mx-auto mt-12 flex max-w-[550px] items-center justify-end space-x-5">
          <div className={textBoxClassName}>
            <div className="w-36 h-16 pt-1">
              <p className="mr-4 font-semibold">¡Contactate con nosotros!</p>
            </div>
          </div>
          <button
            className="flex h-[70px] w-[70px] items-center justify-center rounded-full bg-gradient-to-br from-green-600 to-green-400 text-white border-white  border-0 hover:border-2 shadow-md shadow-green-800"
            aria-label="Botón de Contacto Whatsapp"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <span>
              <Icon whatsapp className={"h-10"} />
            </span>
          </button>
        </div>
      </div>
    </a>
  );
};

export default ChatButton;
