//This component receives as parameter a list of images and creates an auto banner slider
import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import classNames from "classnames";
import Icon from "./Icon";

const BannerSlider = ({ banners, className }) => {
  const bannerClassName = classNames(
    "w-screen items-center h-96 shadow shadow-black"
  );
  const iconBgClassName = classNames(
    " mx-3 lg:h-12 lg:w-12 flex justify-center items-center shadow-black shadow-md bg-gradient-to-r from-amber-700 via-red-600 to-red-800  rounded-md"
  );
  const iconClassName = classNames(
    "bg-neutral-800 h-full w-full flex justify-center items-center rounded-md hover:bg-transparent transition-all duration-300 p-1 "
  );
  const bannerFinalClassName = classNames("relative -mb-2 ", className);
  const settings = {
    infinite: true,
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true,
    autoplaySpeed: 5000, // Interval between slides in milliseconds
    pauseOnFocus: false,
    pauseOnHover: false,
  };

  const slider = banners.map((banner, index) => {
    return (
      <div key={index}>
        <img
          className={bannerClassName}
          src={banner.src}
          alt={"banner " + index}
        />
      </div>
    );
  });

  return (
    <div className={bannerFinalClassName}>
      <div className="h-96 w-full bg-gradient-to-br from-black from-40% to-orange-800 absolute z-20 opacity-50"></div>
      <Slider {...settings}>{slider}</Slider>
      <div className="grid grid-rows-10 grid-cols-8 absolute top-0 left-0 z-30">
        <div className="row-start-2 row-span-4 col-start-2 col-span-3">
          <h1 className="text-gray-50 font-bold text-4xl xl:text-6xl lg:text-5xl uppercase text-left select-none font-inter">
            SMI Soluciones
          </h1>
          <h2 className="text-lg lg:text-xl xl:text-2xl text-gray-50 select-none text-left pt-2 italic font-inter">
            "Grandes ideas para todos los desafíos"
          </h2>
        </div>
        <div className={" col-start-2 row-start-5 lg:row-start-4 mt-5 flex"}>
          <a
            className={iconBgClassName}
            href="https://www.linkedin.com/company/smisoluciones/?originalSubdomain=ar"
            target="_blank"
            rel="noreferrer"
          >
            <div className={iconClassName}>
              <Icon
                linkedin
                disableHover
                className="text-white h-6 m-1 mx-1.5 md:m-0 md:h-8 "
                aria-label="LinkedIn"
              />
            </div>
          </a>
          <a className={iconBgClassName} href="mailto:Ventas@smisoluciones.net">
            <div className={iconClassName}>
              <Icon
                mail
                disableHover
                className="text-white h-6 m-1 md:m-0 md:h-8 "
                aria-label="Mail"
              />
            </div>
          </a>
          <a className={iconBgClassName} href="tel:+5491166883796">
            <div className={iconClassName}>
              <Icon
                phone
                disableHover
                className="text-white h-6 m-1 md:m-0 md:h-8 "
                aria-label="Teléfono"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BannerSlider;
