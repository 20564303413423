import classNames from "classnames";

const Card = ({
  title,
  description,
  icon,
  isHovered,
  setHovered,
  className,
}) => {
  const cardClassName = classNames(
    className,
    "w-full bg-white ease-linear transition-all duration-200 ",
    isHovered ? "shadow-lg shadow-neutral-400" : ""
  );

  const iconClassName = classNames(
    "w-full h-full aspect-square text-black-500 rounded-md shadow shadow-neutral-600 ease-linear transition-all duration-200 bg-neutral-500 flex justify-center align-middle",
    isHovered ? "bg-transparent text-white" : ""
  );

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      className={cardClassName}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="bg-gradient-to-br from-amber-700 via-red-600 to-red-800 rounded-md w-1/6 sm:w-1/12 md:w-1/6 ml-6 -mt-5">
        <div className={iconClassName}>{icon}</div>
      </div>
      <div className="p-6">
        <h3 className="text-lg sm:text-xl font-semibold">{title}</h3>
        <p className="text-center text-sm sm:text-base md:text-sm lg:text-base h-[70%] mt-6 px-4">
          {description}
        </p>
      </div>
    </div>
  );
};

export default Card;
