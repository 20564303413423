import React, { useState } from "react";
import classNames from "classnames";
import { Squash } from "hamburger-react";
import { HashLink } from "react-router-hash-link";

const NavBar = ({ sections }) => {
  const {
    homeIsVisible,
    nosotrosIsVisible,
    solucionesIsVisible,
    experienciaIsVisible,
    clientesIsVisible,
    contactoIsVisible,
  } = sections;

  const linkClassName = classNames(
    "font-bold text-gray-50 hover:bg-gradient-to-r from-amber-600 via-red-500 to-red-700 inline-block hover:text-transparent bg-clip-text ease-linear transition-all duration-200"
  );
  const hoveredLinkClassName = classNames(
    "font-bold bg-gradient-to-r from-amber-600 via-red-500 to-red-700 inline-block text-transparent bg-clip-text"
  );
  const mobileLinkClassName = classNames(
    "block px-4 py-2 text-white hover:underline underline-offset-4 tracking-widest uppercase hover:drop-shadow-lg rounded"
  );
  const contactClassName = classNames(
    "font-bold bg-gray-50 hover:bg-transparent inline-block py-1 px-2 hover:text-gray-50 rounded-md ease-linear transition-all duration-300"
  );
  const hoveredContactClassName = classNames(
    "font-bold bg-transparent inline-block py-1 px-2 text-gray-50 rounded-md ease-linear transition-all duration-300"
  );

  const [isClicked, setIsClicked] = useState(false);
  const mobileDrawerClassName = classNames(
    "mobile-menu block md:hidden",
    isClicked ? "block" : "hidden"
  );

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <header
      className="bg-gradient-to-r from-neutral-950 to-neutral-800 md:to-neutral-700 shadow shadow-black fixed w-full z-40 px-10 md:px-0 "
      data-aos="fade-down"
    >
      <nav className="container mx-auto  py-3">
        <div className="flex items-center justify-between md:mx-5">
          <HashLink to="#home" smooth={"true"}>
            <div className="text-white font-bold text-xl">
              <img
                src="./img/white logo.webp"
                className="h-14 lg:h-20"
                alt="SMI SOLUCIONES"
              />
            </div>
          </HashLink>
          <div className="hidden md:block">
            <ul className="flex items-center space-x-8">
              <li>
                <HashLink to="#home" smooth={"true"}>
                  <h2
                    className={
                      homeIsVisible ? hoveredLinkClassName : linkClassName
                    }
                  >
                    Home
                  </h2>
                </HashLink>
              </li>
              <li>
                <HashLink to="#nosotros" smooth={"true"}>
                  <h2
                    className={
                      !homeIsVisible && nosotrosIsVisible
                        ? hoveredLinkClassName
                        : linkClassName
                    }
                  >
                    Nosotros
                  </h2>
                </HashLink>
              </li>
              <li>
                <HashLink to="#soluciones" smooth={"true"}>
                  <h2
                    className={
                      !nosotrosIsVisible && solucionesIsVisible
                        ? hoveredLinkClassName
                        : linkClassName
                    }
                  >
                    Soluciones
                  </h2>
                </HashLink>
              </li>

              <li>
                <HashLink to="#experiencia" smooth={"true"}>
                  <h2
                    className={
                      !solucionesIsVisible && experienciaIsVisible
                        ? hoveredLinkClassName
                        : linkClassName
                    }
                  >
                    Experiencia
                  </h2>
                </HashLink>
              </li>
              <li>
                <HashLink to="#clientes" smooth={"true"}>
                  <h2
                    className={
                      !experienciaIsVisible && clientesIsVisible
                        ? hoveredLinkClassName
                        : linkClassName
                    }
                  >
                    Clientes
                  </h2>
                </HashLink>
              </li>
              <li className="bg-gradient-to-r from-amber-700 via-red-600 to-red-800 w-full h-full rounded-md">
                <HashLink
                  to="#contacto"
                  smooth={"true"}
                  className={
                    !clientesIsVisible && contactoIsVisible
                      ? hoveredContactClassName
                      : contactClassName
                  }
                >
                  Contacto
                </HashLink>
              </li>
            </ul>
          </div>
          <div className="md:hidden">
            <button
              className="outline-none mobile-menu-button text-slate-100"
              onClick={() => handleClick()}
              aria-label="Botón de Navegación"
            >
              <Squash toggled={isClicked} />
            </button>
          </div>
        </div>
        <div className={mobileDrawerClassName}>
          <ul className="mt-4 space-y-4">
            <li>
              <HashLink
                to="#home"
                smooth={"true"}
                className={mobileLinkClassName}
                onClick={() => {
                  setIsClicked(false);
                }}
              >
                Home
              </HashLink>
            </li>
            <li>
              <HashLink
                to="#nosotros"
                smooth={"true"}
                className={mobileLinkClassName}
                onClick={() => {
                  setIsClicked(false);
                }}
              >
                Nosotros
              </HashLink>
            </li>
            <li>
              <HashLink
                to="#soluciones"
                smooth={"true"}
                className={mobileLinkClassName}
                onClick={() => {
                  setIsClicked(false);
                }}
              >
                Soluciones
              </HashLink>
            </li>
            <li>
              <HashLink
                to="#experiencia"
                smooth={"true"}
                className={mobileLinkClassName}
                onClick={() => {
                  setIsClicked(false);
                }}
              >
                Experiencia
              </HashLink>
            </li>
            <li>
              <HashLink
                to="#clientes"
                smooth={"true"}
                className={mobileLinkClassName}
                onClick={() => {
                  setIsClicked(false);
                }}
              >
                Clientes
              </HashLink>
            </li>
            <li>
              <HashLink
                to="#contacto"
                smooth={"true"}
                className={mobileLinkClassName}
                onClick={() => {
                  setIsClicked(false);
                }}
              >
                Contacto
              </HashLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
