//This component receives as parameter a list of products and creates an auto card slider

import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import classNames from "classnames";

const AutoCardSlider = ({
  slides,
  slidesQuantity,
  pauseOnFocus,
  className,
  autoPlay,
  pauseOnHover,
}) => {
  const finalClassName = classNames("overflow-x-hidden p-2", className);

  const settings = {
    infinite: true,
    slidesToShow: slidesQuantity, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: autoPlay,
    autoplaySpeed: 3000, // Interval between slides in milliseconds
    pauseOnFocus: pauseOnFocus,
    pauseOnHover: pauseOnHover,
  };

  return (
    <div>
      <Slider className={finalClassName} {...settings}>
        {slides.map((slide) => {
          return slide;
        })}
      </Slider>
    </div>
  );
};

export default AutoCardSlider;
