import classNames from "classnames";
import React, { useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";

const InformationCard = ({ imgSource, title, text, textLg }) => {
  const isMd = useMediaQuery("(min-width:768px) and (max-width: 1023px)");
  const isLg = useMediaQuery("(min-width:1024px)  and (max-width: 1279px)");
  const isXl = useMediaQuery("(min-width:1280px)  and (max-width: 1535px)");
  const is2Xl = useMediaQuery("(min-width:1536px)");

  let shownText;
  if (isMd) {
    shownText = text;
  } else if (isLg) {
    shownText = text;
  } else if (isXl) {
    shownText = textLg;
  } else if (is2Xl) {
    shownText = textLg;
  } else {
    shownText = text;
  }

  const [isHovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const whiteCardClassName = classNames(
    "h-52 md:h-64 lg:h-72 xl:h-96 aspect-video  z-0 bg-white ease-linear transition-all duration-500 rounded-xl",
    isHovered
      ? "-mb-0 lg:-mb-36 xl:-mb-64 shadow-lg shadow-neutral-600 rounded-b-none"
      : "-mb-52 md:-mb-64 lg:-mb-72 xl:-mb-96"
  );
  const blackCardClassName = classNames(
    "h-52 md:h-64 lg:h-72 xl:h-96 aspect-video bg-black  z-20 px-7 pt-4 relative ease-linear transition-all duration-500 rounded-xl",
    isHovered
      ? "lg:ml-48 xl:ml-96 bg-opacity-100 shadow shadow-neutral-600 rounded-t-none"
      : "ml-0 bg-opacity-60"
  );
  const imageClassName = classNames(
    "z-10 h-full rounded-xl",
    isHovered ? "rounded-b-none" : ""
  );
  const titleClassName = classNames(
    "text-white font-medium mb-2 ease-linear transition-all duration-500 font-inter-semibold uppercase tracking-wider",
    isHovered
      ? "font-base md:text-xl xl:text-3xl"
      : "text-center text-4xl  tracking-wide mt-5 animate-pulse"
  );
  const textClassName = classNames(
    "text-white text-sm md:text-lg  xl:text-xl lg:tracking-wide line-clamp-3",
    isHovered ? "block" : "hidden"
  );

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-aos="fade-down"
    >
      <div className={whiteCardClassName}>
        <img src={imgSource} className={imageClassName} alt="mainImage" />
      </div>
      <div className={blackCardClassName}>
        <h4 className={titleClassName}>{title}</h4>

        {<p className={textClassName}>{shownText}</p>}
      </div>
    </div>
  );
};

export default InformationCard;
